<script setup>
import {onMounted, ref} from 'vue';

defineProps({
    modelValue: String,
    placeholder: String | undefined,
    hasError: Boolean | undefined,
    borderFocus: {
        type: Boolean,
        default: false,
    },
});

defineEmits(['update:modelValue', 'focus']);

const input = ref(null);
const hideInput = ref(true);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({focus: () => input.value.focus()});
</script>

<template>
    <div :class="{'!border-black dark:border-slate-200':borderFocus && modelValue.length > 0}"
         class="border border-slate-300 dark:border-slate-200 rounded-lg bg-white dark:bg-dark-blue flex items-center overflow-hidden w-full justify-between gap-3 transition-colors relative">
        <input
            ref="input"
            class="border-0 font-normal w-full dark:bg-dark-blue focus:ring-0 pr-12 focus-visible:outline-0 font-sans placeholder:text-gray-400 dark:text-gray-400"
            :class="{'border-red-300': hasError }"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            @focus="$emit('focus')"
            :type="hideInput ? 'password' : 'text'"
            :placeholder="placeholder"
        >
        <ion-icon :name="hideInput ? 'eye-off' : 'eye'"
                  class="absolute right-0 top-0 h-full text-2xl mr-4 text-gray-400 cursor-pointer"
                  @click="hideInput = !hideInput"
        ></ion-icon>
    </div>
</template>
